import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import YouTube from "../components/youtube";


export default () => {

  return (
    <Layout>

    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-band-impulse-heft-2-elise-in-black' className="closeX"><span>X</span></Link>
      <h2>Mixing-Projekt zu <i>Elise In Black</i></h2>
    </div>
    <div style={{ 'marginTop': '20px' }}>
      <p>
        Lernen Sie die Stimmen des Songs <i>Elise In Black</i> kennen, indem Sie die Lautstärke an den einzelnen Tracks verändern. Wenn Sie sich Einzelspuren angehört haben und die Tracks nicht mehr synchron gespielt werden, verwenden Sie die Schaltflächen 'Reset' und 'Start / Stopp' zum Synchronisieren. Die Lautstärke lässt sich an den Audioplayern für jeden Kanal einzeln regeln und mit dem Slider für alle Spuren gleichzeitig. Achten Sie darauf, dass die Sounddateien geladen sind, bevor es losgeht. Viel Spaß beim Mixen meines Bandklassen-Songs <i>Elise In Black</i>...
      </p>
      <div><Link to="/oer-band-impulse-heft-2-elise-in-black"><img src="/Images/kaiser_openBookBandImpulse-2.png" alt="" /></Link></div>
    </div>
    <div style={{ 'marginTop': '40px', 'display': 'flex' }}>
      <button style={{ 'marginRight': '10px' }} id="startStoppButton" onClick={() => onStartStoppClick()}>Start / Stopp</button>       
      <button style={{ 'marginRight': '10px' }} id="resetButton" onClick={() => onResetClick()}>Reset</button>       
      <input style={{ 'marginTop': '4px' }} id="volumeButton" onChange={() => onVolumeChange()} type="range" step="1" />
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/MelodieBeethoven.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Marmotte-Melodie (Beethoven)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Melodie2.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Sexten zur Marmotte-Melodie (2. Stimme)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Melodie3.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Unterstimme (3. Stimme)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/LeadGitarre.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Leadgitarre</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Gitarre.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Akkordgitarre</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Powerchords.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Powerchords</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/E-Bass.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">E-Bass</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/KeyboardMelodie.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Keyboard 1 (<i>Für Elise</i> von Beethoven)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/KeyboardHarmonie.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Keyboard 2 (Harmonien)</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Klatschen.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Händeklatschen</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Cajon.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Cajon</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Conga.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Conga</p>
      </div>
      <div className="flexRowContainer">
        <audio className="mixingAudio" controls loop mediaGroup="master">
          <source src="/data/audio/EliseInBlack/Drums.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p className="audiotext">Drums</p>
      </div>
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div className="ytVideoFrame">
        <YouTube videoSrcURL={'https://www.youtube.com/embed/Q7TKW98pOOE'} videoTitle={'Uriah heep - Lady In Black'} />
          <p>Uriah heep - <i>Lady In Black</i> (Youtube-Video)</p>
          <p>Sie können das Pattern zu jeder der ersten vier Strophen starten, um zu hören, wie sich Elise und die schwarze Lady vertragen.</p>
      </div>
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Mixing Projekt</title>
      <meta name="description" content="Hier finden Sie ein Mixing-Projekt zum Studieren des Arrangements sowie der Einzelstimmen zum einem OpenBook (OER) von Ulrich Kaiser für die Bandklasse." />
      <meta name="keywords" content="Projekt, Bandklasse, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

